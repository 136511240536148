import React from 'react'
import Link from 'gatsby-link'
// import KeyboardEventHandler from 'react-keyboard-event-handler'
import { graphql } from 'gatsby'
import Header from '../components/header'

import Layout from '../components/layout'

export default function Template({ data, pageContext }) {
  const { markdownRemark: post } = data
  const { next, prev } = pageContext
  return (
    <div style={{ marginBottom: 100 }}>
      <Header />
      {/* <KeyboardEventHandler
        handleKeys={['right']}
        onKeyEvent={() => document.querySelector('a.next').click()}
      />
      <KeyboardEventHandler
        handleKeys={['left']}
        onKeyEvent={() => document.querySelector('a.previous').click()}
      /> */}

      <>
        {prev && (
          <Link to={prev.frontmatter.path} className="next" title="next article">
            {/* prevBlog */}
          </Link>
        )}
      </>

      <Layout tilNum={post.frontmatter.postid}>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </Layout>
      <>
        {next && (
          <Link to={next.frontmatter.path} className="previous" title="previous article">
            {/* nextBlog */}
          </Link>
        )}
      </>
    </div>
  )
}

export const postQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        date
        desc
        postid
      }
    }
  }
`
