import React from 'react'
import { Link } from 'gatsby'
import headerIcon from '../images/logo-high.png'

const Header = ({ siteTitle }) => (
  <div
    style={{
      background: 'transparent',
      marginBottom: 0,
    }}
  >
    <div
      style={{
        margin: '0 auto',
        maxWidth: 960,
        padding: '1.45rem 1.0875rem',
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: '#111',
            textDecoration: 'none',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={headerIcon}
            alt="Phoenixgyaan icon"
            style={{ maxWidth: 60, maxHeight: 60 }}
          />
        </Link>
      </h1>
    </div>
  </div>
)

export default Header
